exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanx-lab-js": () => import("./../../../src/pages/advanx-lab.js" /* webpackChunkName: "component---src-pages-advanx-lab-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cart-overview-js": () => import("./../../../src/pages/cart-overview.js" /* webpackChunkName: "component---src-pages-cart-overview-js" */),
  "component---src-pages-covidna-js": () => import("./../../../src/pages/covidna.js" /* webpackChunkName: "component---src-pages-covidna-js" */),
  "component---src-pages-dna-day-js": () => import("./../../../src/pages/dna-day.js" /* webpackChunkName: "component---src-pages-dna-day-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-healthy-recipes-js": () => import("./../../../src/pages/get-healthy-recipes.js" /* webpackChunkName: "component---src-pages-get-healthy-recipes-js" */),
  "component---src-pages-goodbye-2020-js": () => import("./../../../src/pages/goodbye-2020.js" /* webpackChunkName: "component---src-pages-goodbye-2020-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pmcare-js": () => import("./../../../src/pages/pmcare.js" /* webpackChunkName: "component---src-pages-pmcare-js" */),
  "component---src-pages-post-js": () => import("./../../../src/pages/post.js" /* webpackChunkName: "component---src-pages-post-js" */),
  "component---src-pages-press-and-media-js": () => import("./../../../src/pages/press-and-media.js" /* webpackChunkName: "component---src-pages-press-and-media-js" */),
  "component---src-pages-products-dna-explorer-carrier-js": () => import("./../../../src/pages/products/dna-explorer-carrier.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-carrier-js" */),
  "component---src-pages-products-dna-explorer-essential-1-js": () => import("./../../../src/pages/products/dna-explorer-essential1.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-essential-1-js" */),
  "component---src-pages-products-dna-explorer-health-3601-js": () => import("./../../../src/pages/products/dna-explorer-health3601.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-health-3601-js" */),
  "component---src-pages-products-dna-explorer-js": () => import("./../../../src/pages/products/dna-explorer.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-js" */),
  "component---src-pages-products-dna-explorer-onco-js": () => import("./../../../src/pages/products/dna-explorer-onco.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-onco-js" */),
  "component---src-pages-products-dna-explorer-personal-js": () => import("./../../../src/pages/products/dna-explorer-personal.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-personal-js" */),
  "component---src-pages-products-dna-explorer-prime-js": () => import("./../../../src/pages/products/dna-explorer-prime.js" /* webpackChunkName: "component---src-pages-products-dna-explorer-prime-js" */),
  "component---src-pages-products-genetics-fitness-js": () => import("./../../../src/pages/products/genetics-fitness.js" /* webpackChunkName: "component---src-pages-products-genetics-fitness-js" */),
  "component---src-pages-products-genetics-health-js": () => import("./../../../src/pages/products/genetics-health.js" /* webpackChunkName: "component---src-pages-products-genetics-health-js" */),
  "component---src-pages-products-genetics-nutrition-js": () => import("./../../../src/pages/products/genetics-nutrition.js" /* webpackChunkName: "component---src-pages-products-genetics-nutrition-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("./../../../src/templates/blog-post-page.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-discover-genetics-page-js": () => import("./../../../src/templates/discover-genetics-page.js" /* webpackChunkName: "component---src-templates-discover-genetics-page-js" */),
  "component---src-templates-how-it-works-page-js": () => import("./../../../src/templates/how-it-works-page.js" /* webpackChunkName: "component---src-templates-how-it-works-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-minimal-page-js": () => import("./../../../src/templates/minimal-page.js" /* webpackChunkName: "component---src-templates-minimal-page-js" */),
  "component---src-templates-reports-page-js": () => import("./../../../src/templates/reports-page.js" /* webpackChunkName: "component---src-templates-reports-page-js" */),
  "component---src-templates-support-page-js": () => import("./../../../src/templates/support-page.js" /* webpackChunkName: "component---src-templates-support-page-js" */)
}

